import * as React from 'react';
import { Breadcrumb, Grid, Typography } from '@alifd/next';
import styles from './index.module.css';

const { Row, Col } = Grid;

export interface PageHeaderProps {
  breadcrumbs?: Array<{ name: string; path?: string }>;
  title?: string;
  description?: string;
  extra?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ breadcrumbs, title, description, extra }) => {
  const hasExtra = extra != null; // 检查 extra 是否存在

  return (
    <div className={styles.pageHeader}>
      {
        breadcrumbs && breadcrumbs.length > 0 && (
          <Breadcrumb className={styles.breadcrumbs} separator=" / ">
            {breadcrumbs.map((item, idx) => (
              <Breadcrumb.Item key={idx} link={item.path}>{item.name}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )
      }

      <Row>
        <Col span={hasExtra ? 16 : 24}>
          {title && <Typography.Text className={styles.title}>{title}</Typography.Text>}
          {description && <Typography.Text className={styles.description}>{description}</Typography.Text>}
        </Col>
        {hasExtra && (
          <Col span={8} style={{ textAlign: 'right' }}>
            {extra}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PageHeader;
